import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store/store';

// const originalWarn = console.warn;
// console.warn = (message, ...args) => {
//     if (typeof message === 'string' && message.includes('KendoReactError: Support for defaultProps will be removed')) {
//         return; // 특정 경고 메시지를 무시합니다.
//     }
//     originalWarn(message, ...args); // 다른 경고는 그대로 출력
// };

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
      <App />
  </Provider>
);