import * as React from "react";

import {
  Scheduler,
  DayView,
  SchedulerDataChangeEvent,
  MonthView,
  WeekView,
  SchedulerSlot,
  SchedulerSlotProps,
  SchedulerSlotHandle,
  DateHeaderCell,
  DateHeaderCellProps,
  DateHeaderCellHandle,
  SchedulerViewSlot,
  SchedulerViewSlotProps,
  SchedulerViewSlotHandle
} from "@progress/kendo-react-scheduler";
import { FormWithCustomEditor } from "./FormWithCustomEditor";
import '@progress/kendo-date-math/tz/Asia/Seoul';
import weekData from "cldr-core/supplemental/weekData.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import koNumbers from "cldr-numbers-full/main/ko/numbers.json";
import koDateFields from "cldr-dates-full/main/ko/dateFields.json";
import koCurrencies from "cldr-numbers-full/main/ko/currencies.json";
import koCaGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import koTimeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";
import koMessages from "../../utils/ko-KR.json";
import { IntlProvider, load, loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import moment from "moment";
import { useEffect } from "react";
import { deleteSchedule, getSchedules, registerSchedule, updateSchedule } from "../../store/actions/schedule";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { Schedule } from "../../store/types";
import { EditItemWithDynamicTitle } from "./EditItemWithDynamicTitle";
import { JSX } from "react/jsx-runtime";

import './CustomCalendar.css'

load(
  likelySubtags,
  currencyData,
  weekData,
  koNumbers,
  koCurrencies,
  koCaGregorian,
  koDateFields,
  koTimeZoneNames
);
loadMessages(koMessages, "ko-KR");

const CustomCalendar = () => {
  const state = useSelector((state: RootState) => state);
  const schedules = useSelector((state: RootState) => state.schedule.schedules);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (state.user.user?.hospital?.hspCd) {
      dispatch(getSchedules(state.user.user?.hospital?.hspCd))
    }
  }, [dispatch, state.user.user?.hospital?.hspCd]);

  const customModelFields = {
    id: 'id',
    description: 'Note',
    start: 'Start',
    end: 'End',
    title: 'hspCd',
    recurrenceRule: 'RecurrenceRule',
    recurrenceId: 'RecurrenceID',
    recurrenceExceptions: 'RecurrenceException'
  };

  const handleDataChange = async (event: SchedulerDataChangeEvent) => {
    const { created, updated, deleted } = event;

    if (created.length > 0) {
      const newEvent = created[0];
      const newSchedule: Schedule = {
        start: newEvent.Start,
        end: newEvent.End,
        hspCd: state.user.user?.hospital?.hspCd,
        ptCd: newEvent.Patient,
        email: state.user.user?.email,
        note: newEvent.Note,
        department: newEvent.Department
      };
      try {
        await dispatch(registerSchedule(newSchedule));
      } catch (error) {
        console.error("일정 저장 중 오류 발생:", error);
      }
    }

    if (updated.length > 0) {
      const updatedEvent = updated[0];
      const updatedSchedule: Schedule = {
        id: updatedEvent.id,
        start: updatedEvent.Start,
        end: updatedEvent.End,
        hspCd: updatedEvent.hspCd,
        ptCd: updatedEvent.patient.ptCd,
        email: updatedEvent.staff.email,
        note: updatedEvent.Note,
        department: updatedEvent.Department
      };
      try {
        await dispatch(updateSchedule(updatedSchedule));
      } catch (error) {
        console.error("일정 업데이트 중 오류 발생:", error);
      }
    }
    if (deleted.length > 0) {
      const deletedEvent = deleted[0];
      try {
        await dispatch(deleteSchedule(deletedEvent.id));
      } catch (error) {
        console.error("일정 삭제 중 오류 발생:", error);
      }
    }
  };

  const CustomMonthSlot = (props: JSX.IntrinsicAttributes & SchedulerSlotProps & React.RefAttributes<SchedulerSlotHandle>) => {
    let today = moment().toDate().toLocaleDateString();
    let slotDay = props.start.toLocaleDateString();
    let date = props.start.toLocaleDateString('en-EN', {
      weekday: 'long'
    });
    return (
      <React.Fragment>
        <SchedulerSlot
          {...props}
          style={{
            ...props.style,
            // color: (today === slotDay) ? 'rgb(26, 115, 232)' : '',
            fontWeight: (today === slotDay) ? 'bold' : '',
            // backgroundColor: (today === slotDay) ? 'lightgray' : 'white'
          }}
          className={(today === slotDay) ? 'today' : (date === 'Sunday') ? 'sunday' : (date === 'Saturday') ? 'saturday' : ''}
        />
      </React.Fragment>
    );
  };

  const CustomMonthHeader = (props: JSX.IntrinsicAttributes & DateHeaderCellProps & React.RefAttributes<DateHeaderCellHandle>) => {
    const date = props.start.toLocaleDateString('en-EN', { weekday: 'long' });


    return (
      <React.Fragment>
        <DateHeaderCell
          {...props}
          style={{
            ...props.style,

          }}
          className={(date === 'Sunday') ? 'sunday' : (date === 'Saturday') ? 'saturday' : ''}
        />
      </React.Fragment>
    );
  };

  // const CustomItem = (props: SchedulerItemProps) => (
  //   <SchedulerItem
  //       {...props}
  //       style={{ ...props.style, backgroundColor: props.isAllDay ? 'pink' : 'blue' }}
  //   />)
  return (
    <div className="calendar-container layoutContainer">
      <div className="calendar">
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko-KR">
            <Scheduler

              timezone="Asia/Seoul"
              editable={{
                edit: true,
                remove: true,
                drag: true,
                resize: true,
                add: true,
                select: true,
              }}
              modelFields={customModelFields}
              data={schedules}
              onDataChange={handleDataChange}
              editItem={EditItemWithDynamicTitle}
              form={FormWithCustomEditor}

            //  item={CustomItem}
            >
              <MonthView dateHeaderCell={CustomMonthHeader} slot={CustomMonthSlot} itemsPerSlot={7} />
              <WeekView dateHeaderCell={CustomMonthHeader} currentTimeMarker={false} />
              <DayView dateHeaderCell={CustomMonthHeader} numberOfDays={1} currentTimeMarker={false} workDayStart={"08:00"} workDayEnd={"19:00"} />
            </Scheduler>
          </IntlProvider>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default CustomCalendar;