import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Schedule } from '../types/Schedule';

interface ScheduleState {
  schedules: Schedule[];
}

const initialState: ScheduleState = {
  schedules: [],
};

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setSchedules(state:ScheduleState, action: PayloadAction<Schedule[]>) {
      console.log("scheduleSlice setSchedules----->", action.payload);
      state.schedules = action.payload;
    },
    addSchedule(state :ScheduleState, action: PayloadAction<Schedule>) {
      state.schedules.push(action.payload);
    },
    editSchedule(state :ScheduleState, action: PayloadAction<Schedule>) {
      const index = state.schedules.findIndex(
        (schedule) => schedule.id === action.payload.id
      );
      if (index !== -1) {
        state.schedules[index] = action.payload;
      }
    },
    removeSchedule(state:ScheduleState, action: PayloadAction<number>) {
      state.schedules = state.schedules.filter(schedule => schedule.id !== action.payload);
  },
  },
});

export const {removeSchedule, editSchedule, addSchedule, setSchedules } = scheduleSlice.actions;
export default scheduleSlice.reducer;
