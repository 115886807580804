import * as React from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { FieldProps } from '@progress/kendo-react-form';
import { rooms } from './data';
import { useEffect, useState } from 'react';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import api from '../../utils/api';
import Patient from '../../store/types/Patient';

export const TitleEditor = (props: FieldProps) => {
    const userState = useSelector((state: RootState) => state.user);
    const [patients, setPatients] = useState<Patient[]>([]); 

    const handleChange = (event: DropDownListChangeEvent) => {
        if (props.onChange) {
            props.onChange.call(undefined, { value: event.value.id });
        }
    };

    useEffect(() => {
        if (userState.user?.hospital?.hspCd) {
            api.getPatientsAsync(userState.user?.hospital?.hspCd)
                .then(response => {
                    setPatients(response.data);
                })
                .catch(error => {
                    console.error('Error fetching patients:', error);
                });
        }
    }, [userState.user?.hospital?.hspCd]);

    return (
        <DropDownList
            onChange={handleChange}
            value={patients.find((p) => p.id === props.value)}
            data={patients}
            dataItemKey={'id'}
            textField={'name'}
        />
    );
};

export const DepartmentEditor = (props: FieldProps) => {
    const departments = useSelector((state: RootState) => state.department.departments);

    const handleChange = (event: DropDownListChangeEvent) => {
        if (props.onChange) {
            props.onChange.call(undefined, { value: event.value.id });
        }
    };

    return (
        <DropDownList
            onChange={handleChange}
            value={departments.find(t => t.id === props.value)}
            data={departments}
            dataItemKey={'id'}
            textField={'name'}
        />
    );
};

export const TherapistEditor = (props: FieldProps) => {
    const userState = useSelector((state: RootState) => state.user);
    const handleChange = (event: DropDownListChangeEvent) => {
        if (props.onChange) {
            props.onChange.call(undefined, { value: event.value.value });
        }
    };

    return (
        <div>
            <p>{userState.user?.name}</p>
        </div>
        // <DropDownList
        //     disabled={true}
        //     onChange={handleChange}
        //     value={therapists.find(t => t.value === props.value)}
        //     data={therapists}
        //     dataItemKey={'value'}
        //     textField={'name'}
        // />
    );
};

export const RoomEditor = (props: FieldProps) => {
    const handleChange = (event: DropDownListChangeEvent) => {
        if (props.onChange) {
            props.onChange.call(undefined, { value: event.value.id });
        }
    };

    return (
        <DropDownList
            onChange={handleChange}
            value={rooms.find(r => r.id === props.value)}
            data={rooms}
            dataItemKey={'id'}
            textField={'title'}
        />
    );
};