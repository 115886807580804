import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import { registerPatient } from '../../../store/actions/auth';
import { PatientSignUp } from '../../../store/types/PatientSignUp';
import { clearError } from '../../../store/reducers/auth';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import '../SignUp.css';

interface PatientSignUpFormInputs extends PatientSignUp { }

interface PatientSignUpComponentProps {
  code?: string;
}

const validationRules = {
  name: {
    required: '이름을 입력하세요.',
  },
  gender: {
    required: '성별을 선택하세요.',
  },
  password: {
    required: '비밀번호를 입력하세요.',
    minLength: { value: 6, message: '비밀번호는 최소 6자 이상이어야 합니다.' },
  },
  confirmPassword: {
    required: '비밀번호 확인을 입력하세요.',
    validate: (value: string, getValues: () => string) =>
      value === getValues() || '비밀번호가 일치하지 않습니다.',
  },
  birthDate: {
    required: '생년월일을 입력하세요.',
  },
  phoneNumber: {
    required: '휴대전화 번호를 입력하세요.',
    pattern: {
      value: /^[0-9-]*$/,
      message: '숫자만 입력 가능합니다.',
    },
  },
  hospital: {
    required: '병원을 선택하세요.',
  },
  privacyAgreement: {
    required: '개인정보 동의는 필수입니다.',
  },
  thirdPartyAgreement: {
    required: '제3자 정보 제공 동의는 필수입니다.',
  },
};

const PatientSignUpComponent: React.FC<PatientSignUpComponentProps> = ({ code }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const error = useSelector((state: RootState) => state.user.error);
  const hospitals = useSelector((state: RootState) => state.hospital.hospitals);

  const { register, handleSubmit, formState: { errors }, getValues } = useForm<PatientSignUpFormInputs>({
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(clearError());
  }, [dispatch]);



  const onSubmit: SubmitHandler<PatientSignUpFormInputs> = async (data) => {
    try {
      const signUpData = {
        ...data,
        code,
        hspCd: data.hospital
      };
      const result = await dispatch(registerPatient(signUpData));

      navigate('/patient/login', { state: { ptNumber: result.pt_cd, successMessage: `회원가입이 완료되었습니다! 귀하의 환자번호는 ${result.pt_cd}입니다.` } });
    } catch (error) {
      console.error('회원가입 실패:', error);
    }
  };

  const formatPhoneNumber = (value: string) => {
    if (value.length <= 3) return value;
    if (value.length <= 7) return `${value.slice(0, 3)}-${value.slice(3)}`;
    return `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7, 11)}`;
  };

  return (
    <div className="register-container layoutContainer">
      <div className="register-box">
        <h2>환자 회원가입</h2>
        {error && <p className="error-message">{error}</p>}

        <div className="input-group">
          <label>병원 *</label>
          <select
            id="hospital"
            {...register('hospital', validationRules.hospital)}
          >
            <option value="">병원을 선택하세요</option>
            {hospitals.map(hospital => (
              <option key={hospital.hspCd} value={hospital.hspCd}>
                {hospital.name}
              </option>
            ))}
          </select>
          {errors.hospital && <p className="error-message">{errors.hospital.message}</p>}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-group">
            <label>이름 *</label>
            <input
              {...register('name', validationRules.name)}
              placeholder="이름을 입력하세요"
            />
            {errors.name && <p className="error-message">{errors.name.message}</p>}
          </div>

          <div className="input-group">
            <label>성별 *</label>
            <select {...register('gender', validationRules.gender)}>
              <option value="">성별 선택</option>
              <option value="남자">남자</option>
              <option value="여자">여자</option>
            </select>
            {errors.gender && <p className="error-message">{errors.gender.message}</p>}
          </div>

          <div className="input-group">
            <label>비밀번호 *</label>
            <input
              type="password"
              {...register('password', validationRules.password)}
              placeholder="비밀번호를 입력하세요"
            />
            {errors.password && <p className="error-message">{errors.password.message}</p>}
          </div>

          <div className="input-group">
            <label>비밀번호 확인 *</label>
            <input
              type="password"
              {...register('confirmPassword', {
                ...validationRules.confirmPassword,
                validate: (value) =>
                  validationRules.confirmPassword.validate(value, () => getValues('password')),
              })}
              placeholder="비밀번호를 다시 입력하세요"
            />
            {errors.confirmPassword && <p className="error-message">{errors.confirmPassword.message}</p>}
          </div>

          <div className="input-group">
            <label>생년월일 *</label>
            <input
              type="date"
              max='9999-12-31'
              {...register('birthDate', validationRules.birthDate)}
            />
            {errors.birthDate && <p className="error-message">{errors.birthDate.message}</p>}
          </div>

          <div className="input-group">
            <label>전화번호 *</label>
            <input
              type="tel"
              {...register('phoneNumber', validationRules.phoneNumber)}
              maxLength={14}
              placeholder="전화번호를 입력하세요 (숫자만 입력)"
              onChange={(e) => {
                const formatted = formatPhoneNumber(e.target.value.replace(/[^0-9]/g, ''));
                e.target.value = formatted;
              }}
            />
            {errors.phoneNumber && <p className="error-message">{errors.phoneNumber.message}</p>}
          </div>


          {/* 개인정보 동의 */}
          <div className="input-group">
            <label className="content">
              <h4> [개인정보 수집 및 이용에 대한 동의]</h4>

              귀하의 개인정보는 서비스 제공을 위해 필요한 최소한의 정보로서, 이용자의 서비스 이용 시 정확하고 원활한 제공을 위해 수집됩니다.
              <br />
              수집된 정보는 회사의 개인정보 처리방침에 따라 관리되며, 제3자에게 제공되지 않습니다. 이용자는 언제든지 개인정보 제공에 대한 동의를 철회할 수 있습니다.
              <div className="privacy">
                <input
                  type="checkbox"
                  {...register('privacyAgreement', validationRules.privacyAgreement)}
                />

                <span>* 개인정보 수집 및 이용에 대한 동의사항에 대해 충분히 이해하였고 동의합니다.</span>

              </div>

            </label>
            {errors.privacyAgreement && <p className="error-message">{errors.privacyAgreement.message}</p>}
          </div>

          {/* 제3자 정보 제공 동의 */}
          <div className="input-group privacy-policy">
            <label className="content">

              <h4>[제3자 정보 제공에 대한 동의]</h4>

              귀하의 개인정보는 서비스 제공을 위해 필요한 경우 제3자에게 제공될 수 있습니다. 정보 제공 시, 제공되는 정보는 최소한의 정보로 제한되며, 법령에 따라 보호됩니다.
              <br />
              <br />
              정보 제공 대상: [예: 협력업체, 파트너사 등]
              <br />
              제공되는 정보의 항목: 이름, 연락처, 서비스 이용 기록 등
              <br />
              제공 목적: 서비스 개선 및 맞춤형 서비스 제공
              <br />
              제공받는 자의 개인정보 보유 및 이용 기간: 제공 목적이 달성된 후 파기
              <br />


              <div className="privacy">

                <input
                  type="checkbox"
                  {...register('thirdPartyAgreement', validationRules.thirdPartyAgreement)}
                />
                <span>* 제3자 정보 제공에 대한 동의사항을 충분히 이해하였고 동의합니다. </span>

              </div>
            </label>
            {errors.thirdPartyAgreement && <p className="error-message">{errors.thirdPartyAgreement.message}</p>}
          </div>


          <button type="submit" className="register-button">회원가입</button>
        </form>
      </div>
    </div>
  );
};

export default PatientSignUpComponent;
