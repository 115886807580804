import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import { registerStaff, requestVerifyCode, verifyCode } from '../../../store/actions/auth';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { StaffSignUp } from '../../../store/types';
import { clearError } from '../../../store/reducers/auth';
import '../SignUp.css';
import VerificationCode from '../../Common/VerificationCode';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';

interface StaffSignUpFormInputs extends StaffSignUp { }

interface StaffSignUpComponentProps {
}

const validationRules = {
  email: {
    required: '이메일을 입력하세요.',
    validate: (value: string) => {
      if (!value.trim()) {
        return '이메일을 입력하세요.';
      }
      return true;
    },
  },
  password: {
    required: '비밀번호를 입력하세요.',
    minLength: { value: 6, message: '비밀번호는 최소 6자 이상이어야 합니다.' },
  },
  confirmPassword: {
    required: '비밀번호 확인을 입력하세요.',
    validate: (value: string, getValues: () => string) =>
      value === getValues() || '비밀번호가 일치하지 않습니다.',
  },
  name: {
    required: '사용자 이름을 입력하세요.',
  },
  hospital: {
    required: '병원을 선택하세요.',
  },
  position: {
    required: false,
  },
  department: {
    required: false,
  },
};

const StaffSignUpComponent: React.FC<StaffSignUpComponentProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const hospitals = useSelector((state: RootState) => state.hospital.hospitals);
  const error = useSelector((state: RootState) => state.user.error);
  const departments = useSelector((state: RootState) => state.department.departments);
  const [selectedHospitalDomain, setSelectedHospitalDomain] = useState<string>('');
  const [isCodeRequested, setIsCodeRequested] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm<StaffSignUpFormInputs>({
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(clearError());
  }, [dispatch]);

  const handleHospitalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedHospitalHspCd = e.target.value;

    if (!selectedHospitalHspCd) {
      setSelectedHospitalDomain('');
      return;
    }

    const selectedHospital = hospitals.find(h => h.hspCd === selectedHospitalHspCd);

    if (selectedHospital) {
      setSelectedHospitalDomain(selectedHospital.domain);
    }
  };

  const handleRequestVerification = async () => {
    const email = getValues('email');
    setIsVerified(false);

    if (!email || !selectedHospitalDomain) {
      showErrorToast('이메일을 입력해주세요.');
      return;
    }

    const completeEmail = `${email}${selectedHospitalDomain}`;
    setIsCodeRequested(true);

    try {
      await dispatch(requestVerifyCode(completeEmail));
    } catch (err) {
      setIsCodeRequested(false);
      console.error('인증 코드 요청 실패:', err);
    }
  };

  const handleVerifyCode = async (code: string) => {
    const email = `${getValues('email')}${selectedHospitalDomain}`;
    try {
      const result = await dispatch(verifyCode(email, code))
      if (result) {
        setIsVerified(true);
        showSuccessToast('이메일 인증이 완료되었습니다.');
      } else {
        showErrorToast('인증 코드가 올바르지 않습니다.');
      }
    } catch (err) {
      console.error('이메일 인증 실패:', err);
      showErrorToast('인증 코드 확인 중 오류가 발생했습니다.');
    }
  };

  const onSubmit: SubmitHandler<StaffSignUpFormInputs> = async (data) => {

    if (!isVerified) {
      showErrorToast('이메일 인증이 필요합니다.');
      return;
    }

    const completeEmail = `${data.email}${selectedHospitalDomain}`;
    const signUpData = {
      ...data,
      email: completeEmail,
    };
    try {
      await dispatch(registerStaff(signUpData));
      navigate('/staff/login', { state: { email: completeEmail, successMessage: '회원가입이 성공적으로 완료되었습니다.' } });
    } catch (err) {
      console.error('회원가입 실패:', err);
    }
  };

  return (
    <div className="register-container layoutContainer">
      <div className="register-box">
        <h2>의료진 회원가입</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit(onSubmit)}>

          <div className="input-group">
            <label>병원 *</label>
            <select
              id="hospital"
              {...register('hospital', validationRules.hospital)}
              onChange={handleHospitalChange}
            >
              <option value="">병원을 선택하세요</option>
              {hospitals.map(hospital => (
                <option key={hospital.hspCd} value={hospital.hspCd}>
                  {hospital.name}
                </option>
              ))}
            </select>
            {errors.hospital && <p className="error-message">{errors.hospital.message}</p>}
          </div>

          <div className="input-group">
            <label>이메일 *</label>
            <div className='email-container'>
              <input
                type="text"
                {...register('email', validationRules.email)}
                placeholder="이메일을 입력하세요"
              />
              <input
                className='emailAdress'
                type="text"
                value={selectedHospitalDomain}
                readOnly

              />
              {!isCodeRequested ? (
              <button type="button" onClick={handleRequestVerification} className="MainBtncolor verify">
                인증
              </button>
              ) :null
              }
            </div>
            {errors.email && <p className="error-message">{errors.email.message}</p>}

          </div>

          {!isCodeRequested ? null: 
          (
          <VerificationCode onResend={handleRequestVerification} onVerify={handleVerifyCode} isVerified={isVerified} />
              )}
          <div className="input-group">
            <label>비밀번호 *</label>
            <input
              type="password"
              {...register('password', validationRules.password)}
              placeholder="비밀번호를 입력하세요"
            />
            {errors.password && <p className="error-message">{errors.password.message}</p>}
          </div>

          <div className="input-group">
            <label>비밀번호 확인 *</label>
            <input
              type="password"
              {...register('confirmPassword', {
                ...validationRules.confirmPassword,
                validate: (value) =>
                  validationRules.confirmPassword.validate(value, () => getValues('password')),
              })}
              placeholder="비밀번호를 다시 입력하세요"
            />
            {errors.confirmPassword && <p className="error-message">{errors.confirmPassword.message}</p>}
          </div>

          <div className="input-group">
            <label>사용자 이름 *</label>
            <input
              {...register('name', validationRules.name)}
              placeholder="사용자 이름을 입력하세요"
            />
            {errors.name && <p className="error-message">{errors.name.message}</p>}
          </div>

          <div className="input-group">
            <select {...register('position', validationRules.position)}>
              <option value="">직위를 선택하세요</option>
              <option value="의사">의사</option>
              <option value="간호사">간호사</option>
            </select>
            {errors.position && <p className="error-message">{errors.position.message}</p>}
          </div>

          <div className="input-group">
            <label>진료과 (선택사항)</label>
            <select {...register('department', validationRules.department)}>
              <option value="">진료과를 선택하세요</option>
              {departments.map(department => (
                <option key={department.id} value={department.name}>
                  {department.name}
                </option>
              ))}
            </select>
            {errors.department && <p className="error-message">{errors.department.message}</p>}
          </div>
          <button type="submit" className="register-button">회원가입</button>
        </form>
      </div>
    </div>
  );
};

export default StaffSignUpComponent;