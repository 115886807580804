import { Dispatch } from 'redux';
import api from '../../utils/api';
import { Auth, StaffSignUp, PatientSignUp } from '../types/index';
import { login, authFailure } from '../reducers/auth';
import axios from 'axios';


export const registerPatient = (patientUser: PatientSignUp) => {
  return async (dispatch: Dispatch) => {
    return await api.postPatientAsync(patientUser).then((res) => {
      return res.data;
    }).catch(err => {
      dispatch(authFailure(err.response.data.message));
      throw err;
    });
  };
};


export const registerStaff = (staffUser: StaffSignUp) => async (dispatch: Dispatch) => {
    await api.postStaffAsync(staffUser).then((res) => {
        return res
    }).catch(err => {
      dispatch(authFailure(err.response.data.message));
        throw err;
    });
};

export const loginUser = (auth: Auth) => async (dispatch: Dispatch) => {
  return await (auth.email ? api.loginStaffAsync(auth) : api.loginPatientAsync(auth))  
    .then((res) => {
      axios.defaults.headers['Authorization'] = `Bearer ${res.data.token}`;
      localStorage.setItem('token', res.data.token);

      dispatch(login({
        ...res.data,
        hospital: { 
          ...res.data.hospital, 
        },
        userType: auth.email ? "staff" : "patient", 
        isAuthenticated: true,  
      }));
    })
    .catch((err) => {
      console.log(err.response.data.message)
        dispatch(authFailure(err.response.data.message));
      throw err;
    });
};


export const requestVerifyCode = (email: string) => async (dispatch: Dispatch) => {
  try {
    const res = await api.requestCodeAsync(email);
    return res;
  } catch (err: any) {
    dispatch(authFailure(err.response?.data?.message || '오류 발생'));
    throw err;
  }
};

export const verifyCode = (email: string, code: string) => async (dispatch: Dispatch) => {
  try {
    const res = await api.verifyCodeAsync(email, code);
    return res;
  } catch (err: any) {
    dispatch(authFailure(err.response?.data?.message || '오류 발생'));
    throw err;
  }
};

export const verifyToken = (token: string, newPassword: string) => async (dispatch: Dispatch) => {
  try {
    const res = await api.verifyTokenAsync(token, newPassword);
    return res;
  } catch (err: any) {
    dispatch(authFailure(err.response?.data?.message || '오류 발생'));
    throw err;
  }
};

export const requestVerifyToken = (email: string) => async (dispatch: Dispatch) => {
  try {
    const res = await api.requestTokenAsync(email);
    return res;
  } catch (err: any) {
    console.log("requestVerifyToken err 메세지",err.response?.data?.message)
    dispatch(authFailure(err.response?.data?.message || '오류 발생'));
    throw err;
  }
};