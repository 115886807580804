import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { clearError } from '../../../store/reducers/auth';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { verifyToken } from '../../../store/actions/auth';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import '../Password.css'

interface ResetPasswordState {
    newPassword: string;
    confirmPasswordText: string;
    showError: boolean;
    errorText: string;
}

const ResetPasswordComponent: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { token = '' } = useParams<{ token: string }>();
    const error = useSelector((state: RootState) => state.user?.error);

    const [state, setState] = useState<ResetPasswordState>({
        newPassword: '',
        confirmPasswordText: '',
        showError: false,
        errorText: '',
    });

    const {
        newPassword,
        confirmPasswordText,
        showError,
        errorText
    } = state;

    useEffect(() => {
        dispatch(clearError());
    }, [dispatch]);

    const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length <= 20) {
            setState({ ...state, newPassword: e.target.value.toString() });
        }
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length <= 20) {
            setState({ ...state, confirmPasswordText: e.target.value.toString() });
        }
    };

    const resetPasswordButtonClickHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (newPassword !== confirmPasswordText) {
            setState({ ...state, showError: true, errorText: '비밀번호가 일치하지 않습니다.' });
            return;
        }
        if (newPassword.length < 1) {
            setState({ ...state, showError: true, errorText: '비밀번호를 입력하세요' });
        }

        if (newPassword.length < 8 || newPassword.length > 20) {
            setState({ ...state, showError: true, errorText: '비밀번호는 8자 이상 20자 이내로 설정해야 합니다.' });
            return;
        }

        if (newPassword.length > 0) {
            await dispatch(verifyToken(token, newPassword))
                .then(() => {
                    setState({ ...state, showError: false });
                    navigate('/staff/login', { state: { successMessage: '비밀번호가 재설정 되었습니다.' } });
                }).catch(() => {
                    showErrorToast(error + '');
                });
        }
    };

    return (
        <div className='layoutContainer reset-password-page'>
            <div className="reset-password-wrapper">
                <h2>새 비밀번호 설정</h2>
                <p >
                    영문, 숫자를 포함하여 8자 이상 20자 이내로 새 비밀번호를 설정해 주세요.
                </p>
                {showError && <p className="error-message">{errorText}</p>}
                <div className='input-group'>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        placeholder="새 비밀번호"
                    />

                    <input
                        type="password"
                        value={confirmPasswordText}
                        onChange={handleConfirmPasswordChange}
                        placeholder="새 비밀번호 확인"
                    />
                </div>
                <button
                    className='MainBtncolor'
                    onClick={resetPasswordButtonClickHandler}
                >
                    새 비밀번호 설정 완료하기
                </button>
            </div>
        </div>
    );
};

export default ResetPasswordComponent;
