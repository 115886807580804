import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import api from '../../../utils/api';
import { RootState } from "../../../store/store";
import PdfShowComponent from "../../Common/PdfShowComponent";
import PatientWorkoutDetailComponent from "./PatientWorkoutDetailCompnent";
import PdfShow from "../../../store/types/PdfShow";
import '../PatientPage.css'

interface PatientDetail {
  ptCd: string;
}
interface WorkoutRecord {
  workoutUuid: string;
}


const PatientDetailComponent: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // URL에서 id를 가져옴
  const user = useSelector((state: RootState) => state.user.user); // Redux에서 hspCd 가져옴
  const [patient, setPatient] = useState<PatientDetail | null>(null);
  const [workoutRecords, setWorkoutRecords] = useState<WorkoutRecord[]>([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });
  const [selectedPdf, setSelectedPdf] = useState<string | null>(null);
  const [View, setView] = useState<Boolean | null>(false);
  const [selectedRecord, setSelectedRecord] = useState<{ ptCd: string; workoutUuid: string } | null>(null);
  const [selectedWorkoutUuid, setSelectedWorkoutUuid] = useState<string | null>(null);

  useEffect(() => {
    const fetchPatientDetail = async () => {
      if (user?.hospital?.hspCd && id) {
        try {
          const response = await api.getPatientDetailAsync(id, user.hospital.hspCd);
          setPatient(response.data);
          setWorkoutRecords(response.data.patientWorkoutInfo);

        } catch (error) {
          console.error("환자 상세 정보를 가져오는 중 오류 발생:", error);
        }
      }
    };
    fetchPatientDetail();
  }, [id]); // id가 변경될 때만 재실행

  useEffect(() => {
    if (patient && selectedWorkoutUuid) {
      setSelectedRecord({ ptCd: patient.ptCd, workoutUuid: selectedWorkoutUuid });
    }
  }, [patient, selectedWorkoutUuid]);

  const onPageChange = (e: any) => setPage(e.page);

  const handlePdfClick = (pdfFilename: string | null) => {
    if (pdfFilename) {
      setSelectedPdf(pdfFilename);
      setView(true);

    }
   setSelectedWorkoutUuid(null);
   setSelectedRecord(null);
  };

  const closePdfViewer = () => {
    setSelectedPdf(null);
    setView(false);
  };

  const handleRecordDetail = (record: WorkoutRecord) => {
    if (patient?.ptCd) {
      setSelectedWorkoutUuid(record.workoutUuid);
      setSelectedRecord({ ptCd: patient.ptCd, workoutUuid: record.workoutUuid });
    }
    setSelectedPdf(null);
    setView(true);
  };

  const closeRecordDetail = () => {
    setSelectedWorkoutUuid(null);
    setSelectedRecord(null);

    setView(false);
  };

  return (
    <div className="layoutContainer PatientDetailPage">
      <h2>환자 상세 정보</h2>
      {patient && (
        <div className="PatientDetail">
          <div className="PatientInfoGrid">
            <h3>기본 정보</h3>
            <Grid data={[patient]}>
              <Column field="name" title="이름" width="150px" />
              <Column field="gender" title="성별" width="80px" />
              <Column field="birthDate" title="생년월일" width="120px" />
              <Column field="phoneNumber" title="전화번호" width="150px" />
            </Grid>
          </div>

          <div className="PatientHealthGrid">
            <h3>운동 기록</h3>
            <div className={`healthViewer ${View ? 'open' : 'close'}`}>
              <Grid
                className="health"
                data={workoutRecords.slice(page.skip, page.skip + page.take)}
                pageable
                skip={page.skip}
                take={page.take}
                total={workoutRecords.length}
                onPageChange={onPageChange}

              >
                <Column field="dateCreate" title="날짜" />
                <Column field="workoutCode" title="운동 코드" />
                <Column
                  field="recordDetail"
                  title="기록 상세"
                  cell={(props) => (
                    <td>
                      <button className="MainBtncolor openBtn" onClick={() => handleRecordDetail(props.dataItem)}>
                        상세 보기
                      </button>
                    </td>
                  )}
                />
                <Column
                  field="pdfFilename"
                  title="결과보고서"
                  cell={(props) => (
                    <td>
                      {props.dataItem.pdfFilename ? (
                        <button className="MainBtncolor openBtn" onClick={() => handlePdfClick(props.dataItem.pdfFilename)}>
                          열기
                        </button>
                      ) : (
                        "없음"
                      )}
                    </td>
                  )}
                />
              </Grid>

              {selectedRecord && (
                <div className="record-detail-modal">
                  <button className="MainBtncolor closebutton" onClick={closeRecordDetail}>닫기</button>
                  <PatientWorkoutDetailComponent ptCd={selectedRecord.ptCd} workoutUuid={selectedRecord.workoutUuid} />
                </div>
              )}

              {/* 선택된 PDF가 있을 경우 PDF 뷰어 모달 표시 */}
              {selectedPdf && (

                <div className="pdf-modal-content">
                  <button className="MainBtncolor closebutton" onClick={closePdfViewer} style={{ float: 'right' }}>닫기</button>
                  <PdfShowComponent pdfFilename={selectedPdf} />
                </div>

              )}
            </div>
          </div>
        </div>
      )}


    </div>
  );
};

export default PatientDetailComponent;
