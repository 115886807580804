import React from 'react';
import { ReactComponent as PatientSvg } from './Patient.svg';
import { ReactComponent as StaffSvg } from './Doctor.svg';

interface IconProps extends React.SVGProps<SVGSVGElement> {
    color?: string;
}

export const PatientIcon: React.FC<IconProps> = ({ color = 'currentColor', ...props }) => (
    <PatientSvg stroke={color} {...props} />
);

export const StaffIcon: React.FC<IconProps> = ({ color = 'currentColor', ...props }) => (
    <StaffSvg {...props} style={{ fill: color }} />
);
