import React, { useState, useEffect } from 'react';
import './Common.css'

interface VerificationCodeProps {
    onResend: () => void;
    onVerify: (code: string) => void;
    isVerified: boolean;
}

const VerificationCode: React.FC<VerificationCodeProps> = ({ onResend, onVerify, isVerified }) => {
    const initialTime = 180;
    const initialCooldown = 10;
    const [timeLeft, setTimeLeft] = useState(initialTime);
    const [code, setCode] = useState("");
    const [resendCooldown, setResendCooldown] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
        if (isVerified) {
            setTimeLeft(0);
            return;
        }
        if (timeLeft > 0) {
            const timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
            return () => clearInterval(timer);
        }
    }, [timeLeft, isVerified]);

    useEffect(() => {
        if (resendCooldown > 0) {
            const cooldownTimer = setInterval(() => setResendCooldown((prev) => prev - 1), 1000);
            return () => clearInterval(cooldownTimer);
        }
    }, [resendCooldown]);


    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length <= 6) {
            setCode(e.target.value);
        }
    };

    const handleVerifyClick = () => {
        onVerify(code);
    };

    const handleResendClick = () => {
        if (resendCooldown === 0) {
            onResend();
            setTimeLeft(initialTime);
            setCode("");
            setResendCooldown(initialCooldown);
            setErrorMessage(""); 
        } else {
            setErrorMessage("너무 자주 요청하고 있습니다. 잠시 후에 다시 시도하세요.");
        }
    };

    return (
        <div className='VerificationBox' style={{ border: '1px solid #e0e0e0', padding: '20px', borderRadius: '5px', textAlign: 'center' }}>
            <p>이메일로 전송된 인증코드를 입력해주세요.</p>
            <span style={{ color: timeLeft > 0 ? 'black' : 'red', fontWeight: 'bold' }}>
                {formatTime(timeLeft)}
            </span>


            <div className='input-group'>
                <input
                    type="text"
                    placeholder="인증코드 6자리 입력"
                    value={code}
                    onChange={handleCodeChange}
                    style={{
                        padding: '10px',
                        border: '1px solid #e0e0e0',
                        backgroundColor: isVerified ? '#f5f5f5' : '#fff',
                    }}
                    disabled={isVerified}
                />

                <button
                    className='MainBtncolor'
                    type="button"
                    onClick={handleVerifyClick}
                    disabled={code.length !== 6 || isVerified}
                >
                    확인
                </button>
            </div>
            {errorMessage && <p style={{ color: 'red', fontSize: '12px' }}>{errorMessage}</p>}

            <p style={{ fontSize: '12px' }}>
                이메일을 받지 못하셨나요?

                <span onClick={handleResendClick} style={{ color: '#00aaff', cursor: 'pointer' }}>
                    이메일 재전송하기
                </span>
            </p>

        </div>
    );
};

export default VerificationCode;
