import { guid } from "@progress/kendo-react-common";

export const therapists = [
    { value: 'andr', name: "Dr. Anderson", color: "#7F96FF" },
    { value: 'bail', name: "Dr. Bailey", color: "#E56399" },
    { value: 'burg', name: "Dr. Burgess", color: '#A6CFD5' },
    { value: 'quin', name: "Dr. Quinn", color: '#320E3B' }
];

export const rooms = [
    { id: 1, title: 'Bedford' },
    { id: 2, title: 'Sofia' }
];

export const treatments = [
    { value: 138, text: '내과' },
    { value: 189, text: '정신과' },
    { value: 392, text: '외과' },
    { value: 491, text: '치과' }
];

export const patients = [
    { id: 0, name: 'Liam' },
    { id: 1, name: 'Olivia' },
];

const baseData = [
    { "Start": "2020-06-24T08:00:00.000Z", "End": "2020-06-24T08:30:00.000Z", "isAllDay": false, "Room": 1, "Therapist": "andr", "Patient": 0, "Treatment": 138, "AppointmentID": "6aa39025-019a-43b6-9270-3de34b7028ef" },
    { "Start": "2020-06-24T09:00:00.000Z", "End": "2020-06-24T09:30:00.000Z", "isAllDay": false, "Room": 2, "Therapist": "andr", "Patient": 1, "Treatment": 189, "AppointmentID": "b85d65b4-8580-49fd-bf95-7d4074d20948" },
    { "Start": "2020-06-24T09:30:00.000Z", "End": "2020-06-24T11:45:00.000Z", "isAllDay": false, "Room": 3, "Therapist": "bail", "Patient": 3, "Treatment": 491, "AppointmentID": "bb7c5576-e6dd-49fa-ba32-bd2a8f2b81eb" },
    { "Start": "2020-06-24T10:00:00.000Z", "End": "2020-06-24T10:45:00.000Z", "isAllDay": false, "Room": 4, "Therapist": "andr", "Patient": 5, "Treatment": 189, "AppointmentID": "e34e2cf7-b6ff-4efa-a6f0-853d642e2707" },
    { "Start": "2020-06-24T08:00:00.000Z", "End": "2020-06-24T09:00:00.000Z", "isAllDay": false, "Room": 5, "Therapist": "bail", "Patient": 3, "Treatment": 189, "AppointmentID": "4c5a291e-2d7a-4c6c-8ea8-fd962f07c207" },
    { "Start": "2020-06-24T11:30:00.000Z", "End": "2020-06-24T12:00:00.000Z", "isAllDay": false, "Room": 6, "Therapist": "andr", "Treatment": 491, "Patient": 5, "AppointmentID": "501cf356-3af0-400a-9ff9-76ea7aa0d3b2" }
];

export const customModelFields = {
    id: 'id',
    description: 'Note',
    start: 'Start',
    end: 'End',
    title: 'hspCd',
    recurrenceRule: 'RecurrenceRule',
    recurrenceId: 'RecurrenceID',
    recurrenceExceptions: 'RecurrenceException'
};

const currentYear = new Date().getFullYear();
export const displayDate = new Date(Date.UTC(currentYear, 5, 24));
const parseAdjust = (eventDate: string | number | Date) => {
    const date = new Date(eventDate);
    date.setFullYear(currentYear);
    return date;
};


export const sampleDataWithCustomSchema = baseData.map(dataItem => (
    {
        ...dataItem,
        AppointmentID: guid(),
        Start: parseAdjust(dataItem.Start),
        End: parseAdjust(dataItem.End)
    }
));