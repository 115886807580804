import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Department from '../types/Department';

interface DepartmentState {
  departments: Department[];
}

const initialState: DepartmentState = {
  departments: [] as Department[],
};

const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    setDepartments(state, action: PayloadAction<Department[]>) {
      state.departments = action.payload;
    },
  },
});

export const { setDepartments } = departmentSlice.actions;
export default departmentSlice.reducer;
