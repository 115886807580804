import api from "../../utils/api";
import { Dispatch } from 'redux';
import { Schedule } from "../types";
import { addSchedule, editSchedule, removeSchedule, setSchedules } from "../reducers/schedule";

export const formatScheduleData = (data: any) => {
    const start = new Date(data.start || data.startTime);
    const end = new Date(data.end || data.endTime);
    const hoursDifference = (end.getTime() - start.getTime()) / (1000 * 60 * 60);

    return {
        ...data,
        Start: start,
        End: end,
        Patient: data.patient?.id,
        Note: data.note,
        Department: data.department?.id,
        isAllDay: hoursDifference >= 6,
    };
};

export const getSchedules = (hspCd: String) => async (dispatch: Dispatch) => {
    try {
        const response = await api.getSchedulesAsync(hspCd);
        const formattedData = response.data.map((item: any) =>  formatScheduleData(item));

        dispatch(setSchedules(formattedData));
    } catch (err) {
        throw err;
    }
};

export const registerSchedule = (schedule: Schedule) => async (dispatch: Dispatch) => {
    try {
        const response = await api.postScheduleAsync(schedule);
        const formattedData = formatScheduleData(response.data)

        dispatch(addSchedule(formattedData));
    } catch (err) {
        throw err;
    }
};

export const updateSchedule = (schedule: Schedule) => async (dispatch: Dispatch) => {
    try {
        const response = await api.updateScheduleAsync(schedule);
        const formattedData = formatScheduleData(response.data);

        dispatch(editSchedule(formattedData));
    } catch (err) {
        throw err;
    }
}

export const deleteSchedule = (id: number) => async (dispatch: Dispatch) => {
    try {
        await api.deleteScheduleAsync(id);
        dispatch(removeSchedule(id)); 
    } catch (err) {
        throw err;
    }
};