import React, { useEffect, useMemo, useState } from 'react';
import { loginUser } from '../../../store/actions/auth';
import { Auth } from '../../../store/types';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { showSuccessToast } from '../../../utils/toastUtils';
import { PatientIcon, StaffIcon } from '../../Img/Icons';
import '../Auth.css';

interface LoginState {
  loginText: string;
  passwordText: string;
  showError: boolean;
  errorText: string;
  saveId: boolean;
  errorIdText: boolean;
  errorPwdText: boolean;
}

interface LoginComponentProps {
  code?: string;
}

const PatientLoginComponent: React.FC<LoginComponentProps> = ({ code }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = useMemo(() => location.state || {}, [location.state]);

  const [state, setState] = useState<LoginState>({
    loginText: '',
    passwordText: '',
    showError: false,
    errorText: '',
    saveId: false,
    errorIdText: false,
    errorPwdText: false,
  });

  const {
    loginText,
    passwordText,
    showError,
    errorText,
    saveId,
    errorIdText,
    errorPwdText,
  } = state;

  useEffect(() => {
    const savedId = localStorage.getItem('savedId');
    if (locationState.ptNumber) {
      setState((prevState) => ({
        ...prevState,
        loginText: locationState.ptNumber,
        saveId: true,
      }));
    }
    else if (savedId) {
      setState((prevState) => ({
        ...prevState,
        loginText: savedId,
        saveId: true,
      }));
    }
    if (locationState.successMessage) {
      showSuccessToast(locationState.successMessage);
    }
  }, [locationState]);


  const onChangeLoginInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length <= 15) {
      setState({ ...state, loginText: e.target.value.toString() });
    }
  };

  const onChangePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length <= 20) {
      setState({ ...state, passwordText: e.target.value });
    }
  };

  const loginButtonClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (loginText.length > 0 && passwordText.length > 0) {

      loginProcess();
    }
    if (loginText.length < 1) {
      setState({ ...state, showError: true, errorText: '환자번호를 입력하세요', errorPwdText: false });
    } else if (passwordText.length < 1) {
      setState({ ...state, showError: true, errorText: '비밀번호를 입력하세요', errorPwdText: false });
    }
  };

  const loginProcess = async () => {
    saveIdToLocalStorage();

    const auth: Auth = {
      ptCd: loginText,
      password: passwordText,
      code: code
    };

    await dispatch(loginUser(auth))
      .then(() => {
        console.log('Login successful');
        navigate('/');
      })
      .catch((err) => {
        console.error('Login failed', err);
        setState({
          ...state,
          showError: true,
          errorText: err.response?.data?.message,
        });
      });
  };

  const handleSaveIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, saveId: e.target.checked });
  };

  const saveIdToLocalStorage = () => {
    if (saveId) {
      localStorage.setItem('savedId', loginText);
    } else {
      localStorage.removeItem('savedId');
    }
  };

  return (
    <div className="login-container layoutContainer">
      <div className="login-box">
        <PatientIcon width="120" height="120" color="#436dbb" />

        <h2>환자용 로그인</h2>
        <div className="input-group">
          <input
            type="text"
            value={loginText}
            onChange={onChangeLoginInput}
            placeholder="환자 번호를 입력해주세요."
            className={errorIdText ? 'input-error' : ''}
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            value={passwordText}
            onChange={onChangePasswordInput}
            placeholder="비밀번호를 입력해주세요."
            className={errorPwdText ? 'input-error' : ''}
          />
        </div>
        <div className="checkbox-group">
          <label>
            <input
              type="checkbox"
              checked={saveId}
              onChange={handleSaveIdChange}
            />
            아이디 저장
          </label>
        </div>
        {showError && <p className="error-message">{errorText}</p>}

        <button onClick={loginButtonClickHandler} className="login-button">로그인</button>
        <Link className='moreBtn DarkBtncolor' to="/patient/register" >회원가입</Link>
      </div>
    </div>
  );
};

export default PatientLoginComponent;