import { configureStore } from '@reduxjs/toolkit'
import authReducer from './reducers/auth'
import hospitalReducer from './reducers/hospital'
import scheduleReducer from './reducers/schedule'
import departmentReducer from './reducers/department'

export const store = configureStore({
    reducer: {
        user:authReducer,
        hospital:hospitalReducer,
        schedule:scheduleReducer,
        department:departmentReducer,
    },
    
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: false,  // 직렬화 검사 비활성화
        }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch