import React, { useState, useEffect } from "react";
import { RootState } from "../../../store/store";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { Grid, GridColumn as Column, GridPageChangeEvent, GridSortChangeEvent, GridCellProps } from "@progress/kendo-react-grid";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import api from '../../../utils/api';
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { orderBy } from "@progress/kendo-data-query";
import '../PatientPage.css'

interface Patient {
  id: number;
  ptCd: string;
  name: string;
  gender: string;
  birthDate: string;
}

const PatientListComponent = () => {
  const [patients, setPatients] = useState<Patient[]>([]);
  const [filteredPatients, setFilteredPatients] = useState<Patient[]>([]); // 필터링된 데이터
  const [searchQuery, setSearchQuery] = useState(""); // 검색어 상태 추가
  const [sort, setSort] = useState<{ field: string, dir: "asc" | "desc" | undefined }[]>([]);
  const [page, setPage] = useState({ skip: 0, take: patients?.length || 20 });
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useAppDispatch();

  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();


  useEffect(() => {
    const fetchPatients = async () => {
      if (user?.hospital?.hspCd) {
        try {
          const response = await api.getPatientListAsync(user.hospital.hspCd);
          setPatients(response.data); // 원본 데이터 저장
          setFilteredPatients(response.data); // 필터링 데이터 초기화
        } catch (error) {
          console.error("Error fetching patients:", error);
        }
      }
    };

    fetchPatients();
  }, []); // 빈 배열로 설정하여 첫 렌더링 시 한 번만 실행

  const onSortChange = (e: GridSortChangeEvent) => {
    setSort(e.sort as Array<{ field: string, dir: "asc" | "desc" | undefined }>);
    setPatients(orderBy(patients, e.sort));
  };

  const onPageChange = (e: GridPageChangeEvent) => {

    const targetEvent = e.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? patients.length : e.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...e.page,
      take,
    });
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

     // 필터링 로직
     const filtered = patients.filter((patient) =>
      patient.name.toLowerCase().includes(query)
    );
    setFilteredPatients(filtered);
    setPage({ skip: 0, take: 20 }); // 검색 시 페이지 초기화
  };

  const DetailLinkCell = (props: GridCellProps) => {
    return (
      <td>
        <Link to={`/patientList/${props.dataItem.id}`} className="link">
          {props.dataItem.name}
        </Link>
      </td>
    );
  }

  return (
    <div className="PatientListContainer layoutContainer">
      <h2>환자 리스트</h2>
       
       {/* 검색 입력 필드 */}
      <input
        type="text"
        placeholder="환자 이름 검색"
        value={searchQuery}
        onChange={onSearchChange}
        className="search-input"
        style={{ marginBottom: "10px", padding: "5px", width: "300px" }}
      />

      <Grid
        data={patients.slice(page.skip, page.skip + page.take)} // 페이지 데이터 처리
        style={{ height: '400px' }}
        sortable
        sort={sort}
        onSortChange={onSortChange}
        pageable={{
          buttonCount: 5,
          pageSizes: [20, 40, "All"],
          pageSizeValue: pageSizeValue,
        }}
        skip={page.skip}
        take={page.take}
        total={patients.length}
        onPageChange={onPageChange}

      >
        <Column field="id" title="No" width="40px" />
        <Column field="ptCd" title="환자코드" width="100px" />
        <Column field="name" title="이름" cell={DetailLinkCell} width="150px" />
        <Column field="gender" title="성별" width="80px" />
        <Column field="birthDate" title="생년월일" width="120px" />
      </Grid>
    </div>
  );
};

export default PatientListComponent;
