import * as React from 'react';
import { FormElement, Field } from '@progress/kendo-react-form';
import { Label, Error } from '@progress/kendo-react-labels';
import { TextArea } from '@progress/kendo-react-inputs';
import { DatePicker, DateTimePicker} from '@progress/kendo-react-dateinputs';
import { SchedulerFormEditorProps } from '@progress/kendo-react-scheduler';
import { TitleEditor, DepartmentEditor } from './editors';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

export const CustomFormEditor = (props: SchedulerFormEditorProps) => {
  const userState = useSelector((state: RootState) => state.user);

  return (

    <FormElement horizontal={true}  >
      <div className="k-form-field">
        <Label>
          환자
        </Label>
        <div className="k-form-field-wrap">
          <Field
            name={'Patient'}
            component={TitleEditor}
          />
          {props.errors.Patient && <Error>{props.errors.Patient}</Error>}
        </div>
      </div>
      <div className="k-form-field">
        <Label>
          예약 시간
        </Label>
        <div className="k-form-field-wrap">
          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }} >
            <Field
              name={'Start'}
              component={props.startEditor || DatePicker}
              as={DateTimePicker}
              rows={1}
              width={'140px'}
              format="t"
            />
            &nbsp;
            <Label>
              -
            </Label>
            &nbsp;
            <Field
              name={'End'}
              component={props.endEditor || DatePicker}
              as={DateTimePicker}
              rows={1}
              width={'140px'}
              format="t"
            />
          </div>
        </div>
      </div>
      <div className="k-form-field">
        <Label>
          담당 의료진
        </Label>
        <div style={{ padding: '8px', backgroundColor: '#f5f5f5', border: '1px solid #ddd' }}>
          <span>{userState.user?.name}</span>  {/* 이름을 텍스트로 표시 */}
        </div>
        {/* <div className="k-form-field-wrap">
          <Field
            name={'Therapist'}
            component={TherapistEditor}
          />
        </div> */}
      </div>
      <div className="k-form-field">
        <Label>
          진료과
        </Label>
        <div className="k-form-field-wrap">
          <Field
            name={'Department'}
            component={DepartmentEditor}
          />
          {props.errors.Department && <Error>{props.errors.Department}</Error>}
        </div>
      </div>
      <div className="k-form-field">
        <Label>
          Note
        </Label>
        <div className="k-form-field-wrap">
          <Field
            name={'Note'}
            component={TextArea}
            rows={1}
          />
        </div>
      </div>

      {/* <div className="k-form-field">
          <Label>
            Room
          </Label>
          <div className="k-form-field-wrap">
            <Field
              name="Room"
              component={RoomEditor}
                    />
          </div>
        </div> */}
    </FormElement>
  );
};