import api from "../../utils/api";
import { Dispatch } from 'redux';
import { setDepartments } from "../reducers/department";

export const getDepartments = () => async (dispatch: Dispatch) => {
    return await api.getDepartmentsAsync().then((res) => {
        dispatch(setDepartments(res));
    }).catch(err => {
        throw err;
    });
};