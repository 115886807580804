import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppDispatch } from './hooks/useAppDispatch';
import { ToastContainer } from 'react-toastify';
import MainLayout from './components/MainLayout';
import Header from './components/Header';
import StaffLoginComponent from './components/Auth/Staff/StaffLoginComponent';
import StaffSignUpComponent from './components/Auth/Staff/StaffSignUpComponent';
import PatientLoginComponent from './components/Auth/Patient/PatientLoginComponent';
import PatientSignUpComponent from './components/Auth/Patient/PatientSignUpComponent';
import PatientListComponent from './components/Auth/Patient/PatientListComponent';
import PatientDetailComponent from './components/Auth/Patient/PatientDetailComponent';
import 'react-toastify/dist/ReactToastify.css';
import '@progress/kendo-theme-default/dist/all.css';
import api from './utils/api';
import { Auth } from './store/types';
import { login } from './store/reducers/auth';
import CustomCalendar from './components/Calendar/CustomCalendar';
import { getDepartments } from './store/actions/department';
import { getHospitals } from './store/actions/hospital';
import PdfViewerComponent from './components/Common/PdfShowComponent';
import PdfShowComponent from './components/Common/PdfShowComponent';
import ForgotPasswordComponent from './components/Auth/Staff/ForgotPasswordComponent';
import ResetPasswordComponent from './components/Auth/Staff/ResetPasswordComponent';

const App: React.FC = () => {

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDepartments());
    dispatch(getHospitals());

    const token = localStorage.getItem('token');

    if (token) {
      api.verifyUserAsync(token)
        .then((res) => {
          const auth: Auth = {
            ...res.data,
            isAuthenticated: true,
          };
          dispatch(login(auth));
        })
        .catch((error) => {
          console.error("유효성 검증 실패:", error);
          localStorage.removeItem("token");
        });
    }

  }, [dispatch]);




  return (
    <BrowserRouter>
      <ToastContainer />
      <Header />
      <Routes>
        <Route path="/schedule-management" element={<CustomCalendar />} />
        <Route path="/staff/login" element={<StaffLoginComponent />} />
        <Route path="/staff/register" element={<StaffSignUpComponent />} />
        <Route path="/staff/forgot-password" element={<ForgotPasswordComponent />} />
        <Route path="/staff/reset-password/:token" element={<ResetPasswordComponent />} />
        <Route path="/patient/login" element={<PatientLoginComponent />} />
        <Route path="/patient/login/:code" element={<PatientLoginComponent />} />
        <Route path="/patient/register" element={<PatientSignUpComponent />} />
        <Route path="/patient/register/:code" element={<PatientSignUpComponent />} />
        <Route path="/patientList" element={<PatientListComponent />} />
        <Route path="/patientList/:id" element={<PatientDetailComponent />} />
        <Route path="/view-pdf/:pdfFilename" element={<PdfShowComponent pdfFilename='' />} />

        <Route path="/" element={<MainLayout />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
