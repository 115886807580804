import axios from 'axios';
import { StaffSignUp, PatientSignUp, Auth, Schedule } from '../store/types';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URI || 'http://localhost:8080',
  // baseURL: 'http://localhost:8080',
  // withCredentials: true
});


export const postPatientAsync = async (patientSignUp: PatientSignUp) => {
  console.log("patientSignUp api :", patientSignUp)
  return await apiClient.post('/api/patient/register', {
    ...patientSignUp,
  });
};

export const postStaffAsync = async (staffSignUp: StaffSignUp) => {
  return await apiClient.post('/api/staff/register', {
    ...staffSignUp,
  });
};

export const requestCodeAsync = async (email: string) => {
  console.log("requestVerificationCode API --->", email);
  const response = await apiClient.post(`/api/auth/request/verify-code?email=${encodeURIComponent(email)}`);
  return response.data;
};

export const verifyCodeAsync = async (email: string, code: string) => {
  console.log("verifyVerificationCode API --->", email, code);
  const response = await apiClient.post(`/api/auth/verify-code?email=${encodeURIComponent(email)}&code=${code}`);
  return response.data;
};

export const requestTokenAsync = async (email: string) => {
  console.log("requestTokenAsync API --->", email);
  const response = await apiClient.post(`/api/auth/request-password-reset?email=${encodeURIComponent(email)}`);
  console.log("requestTokenAsync API --->", response);
  return response.data;
};

export const verifyTokenAsync = async (token: string, newPassword: string) => {
  console.log("verifyTokenAsync API --->", token);
  const response = await apiClient.post(`/api/staff/password-reset?token=${token}&newPassword=${newPassword}`);
  return response;
};


export const getHospitalsAsync = async () => {
  const response = await apiClient.get('/api/hospital');
  return response.data;
};

export const getDepartmentsAsync = async () => {
  const response = await apiClient.get('/api/department');
  return response.data;
};

export const getPatientsAsync = async (hspCd: string) => {
  const response = await apiClient.get(`/api/patient/list?hspCd=${hspCd}`);
  return response;
};


export const loginPatientAsync = async (auth: Auth) => {
  return await apiClient.post('/api/patient/login', auth);
};

export const loginStaffAsync = async (auth: Auth) => {
  const response = await apiClient.post('/api/staff/login', auth);
  return response;
};

export const postScheduleAsync = async (schedule: Schedule) => {
  return await apiClient.post('/api/schedule/register', {
    ...schedule,
  });
};

export const getSchedulesAsync = async (hspCd: String) => {
  const response = await apiClient.get('/api/schedule/list', {
    params: { hspCd }
  });
  return response;
};

export const verifyUserAsync = async (token: String) => {
  const response = await apiClient.get('/api/auth/verify-token', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response;
};

export const getPatientListAsync = async (hspCd: string) => {
  const response = await apiClient.get(`/api/patient/list?hspCd=${hspCd}`);
  return response;
};

export const getPatientDetailAsync = async (id: string, hspCd: string) => {
  const response = await apiClient.get(`/api/patient/list/${id}?hspCd=${hspCd}`);
  return response;
};

export const getPatientWorkoutDetail = async (ptcd: string, workoutUuid: string ,hspCd: string) => {
  const response = await apiClient.get(`/api/patient/list/${ptcd}/${workoutUuid}?hspCd=${hspCd}`);
  return response;
};

export const getPdfViewerAsync = async (pdfFilename: string) => {
  const response = await apiClient.get(`/api/salted/view-pdf/${pdfFilename}`);
  return response;
};

export const updateScheduleAsync = async (schedule: Schedule) => {
  const response = await apiClient.put('/api/schedule/update', schedule)
  return response;
};

export const deleteScheduleAsync = async (id: Number) => {
  const response = await apiClient.delete(`/api/schedule/delete/${id}`);
  return response.data;
};


const api = {
  getPatientListAsync,
  requestCodeAsync,
  verifyCodeAsync,
  requestTokenAsync,
  verifyTokenAsync,
  deleteScheduleAsync,
  updateScheduleAsync,
  getDepartmentsAsync,
  getPatientsAsync,
  verifyUserAsync,
  loginPatientAsync,
  postPatientAsync,
  loginStaffAsync,
  postStaffAsync,
  getHospitalsAsync,
  postScheduleAsync,
  getSchedulesAsync,
  getPatientDetailAsync,
  getPdfViewerAsync,
  getPatientWorkoutDetail
};

export default api;