import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Auth } from '../types/index';

interface UserState {
  user: Auth | null;
  error : string | null;
}

const initialState: UserState = {
  user: null,
  error : null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state, action: PayloadAction<Auth>) {
      state.user = action.payload;
      state.error = null; 
    },
    logout(state) {
      state.user = null;
    },
    setPatientNumber(state, action: PayloadAction<Auth>) {
      state.user = action.payload;
    },
    setUserType: (state, action: PayloadAction<Auth>) => {
      state.user = action.payload;
    },
    authFailure(state, action: PayloadAction<string>) {
      state.error = action.payload; 
    },
    clearError(state) {
      state.error = null;
    },
  },
});

export const { clearError, login, logout, setPatientNumber, setUserType, authFailure } = userSlice.actions;
export default userSlice.reducer;
