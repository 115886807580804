import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Hospital } from '../types/Hospital';

interface HospitalState {
  hospitals: Hospital[];
}

const initialState: HospitalState = {
  hospitals: [] as Hospital[],
};

const hospitalSlice = createSlice({
  name: 'hospital',
  initialState,
  reducers: {
    setHospitals(state, action: PayloadAction<Hospital[]>) {
      state.hospitals = action.payload;
    },
  },
});

export const { setHospitals } = hospitalSlice.actions;
export default hospitalSlice.reducer;
