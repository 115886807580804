import * as React from 'react';
import { SchedulerForm, SchedulerFormProps } from '@progress/kendo-react-scheduler';
import { CustomDialog } from './CustomDialog';
import { CustomFormEditor } from './CustomFormEditor';

export const FormWithCustomEditor = (props: SchedulerFormProps) => {

    const requiredValidator = React.useCallback(
        
        (value: string | null | undefined) => (value === undefined || value === null || value === ''
            ? '필수 입력 항목입니다.'
            : undefined),
            []
    );

    const formValidator = (_dataItem: any, formValueGetter: (arg0: string) => any) => {
        let result: any = {};
        result.Patient = [
            requiredValidator(formValueGetter('Patient'))
        ].filter(Boolean).reduce((current, acc) => current || acc, '');

        result.Department = [
            requiredValidator(formValueGetter('Department'))
        ].filter(Boolean).reduce((current, acc) => current || acc, '');

        return result;
    };

    return (
      <SchedulerForm
        {...props}
        editor={CustomFormEditor }
        dialog={CustomDialog}
        validator={formValidator}
        />
    );
};