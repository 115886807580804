import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PDFViewer, PDFViewerTool } from "@progress/kendo-react-pdf-viewer";
import api from "../../utils/api"; // API 클라이언트 설정 파일
import { RootState } from "../../store/store";
import PdfShow from "../../store/types/PdfShow";

interface PdfShowProps {
  // pdfShow : PdfShow;
  pdfFilename: string;
}
const PdfShowComponent: React.FC<PdfShowProps> = (props) => {
  const [pdfUrl, setPdfUrl] = useState<string>("");

  const tools: PDFViewerTool[] = [
    "selection",
    "spacer",
    "pager",
    "spacer",
    "search",
    "open",
    "download",
    "print"
  ];

  useEffect(() => {
    const fetchPdfUrl = async () => {
      if (props.pdfFilename) {
        try {
          const response = await api.getPdfViewerAsync(props.pdfFilename);
          setPdfUrl(response.data); // PDF 파일의 URL을 상태에 설정
          console.log(response.data);
        } catch (error) {
          console.error("PDF 파일을 가져오는 중 오류 발생:", error);
        }
      }
    };

    fetchPdfUrl();
  }, [props.pdfFilename]);

  return (
    <div className="pdfContainer">
      {pdfUrl ? (
        <PDFViewer
          tools={tools}
          url={pdfUrl}
          zoom={1}
          style={{ height: "100%", width: "100%" }}
        />
      ) : (
        <p>PDF 파일을 불러오는 중입니다...</p>
      )}
    </div>
  );
};

export default PdfShowComponent;