import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardTitle, CardBody } from "@progress/kendo-react-layout";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import api from '../../../utils/api';
import { PatientWorkoutDetail } from "../../../store/types/PatientWorkoutDetail";
import '../PatientPage.css'

interface PatientWorkoutDetailProps {
    ptCd: string;
    workoutUuid: string;
}



const PatientWorkoutDetailComponent: React.FC<PatientWorkoutDetailProps> = (props) => {
    const user = useSelector((state: RootState) => state.user.user);
    const [patientWorkoutDetail, setPatientWorkoutDetail] = useState<PatientWorkoutDetail | null>(null);

    useEffect(() => {
        const fetchPatientWorkoutDetail = async () => {
            if (user?.hospital?.hspCd) {
                try {
                    const response = await api.getPatientWorkoutDetail(props.ptCd, props.workoutUuid, user.hospital.hspCd);
                    setPatientWorkoutDetail(response.data);
                } catch (error) {
                    console.error("Error fetching WorkoutDetail:", error);
                }
            }
        };

        fetchPatientWorkoutDetail();
    }, [props.workoutUuid]);



    return (
        <div className="recordContainer">
            <Card>
                <CardHeader>
                    <CardTitle>Workout props - {patientWorkoutDetail?.workoutCode}</CardTitle>
                </CardHeader>
                <CardBody>
                    <p><strong>Patient Code:</strong> {props.ptCd}</p>
                    <p><strong>Workout UUID:</strong> {props.workoutUuid}</p>
                    <p><strong>Workout Time:</strong> {patientWorkoutDetail?.workoutTime != null ? `${patientWorkoutDetail.workoutTime} millisecond` : ''}</p>
                    <p><strong>Step Count:</strong> {patientWorkoutDetail?.stepCount != null ? `${patientWorkoutDetail.stepCount} step` : ''}</p>
                    <p><strong>Cadence:</strong> {patientWorkoutDetail?.cadence != null ? `${patientWorkoutDetail.cadence} step/min` : ''}</p>


                    <PanelBar>
                        <PanelBarItem title="Ground Contact Time">
                            <p><strong>Right:</strong> {patientWorkoutDetail?.rightGroundContactTime != null ? `${patientWorkoutDetail.rightGroundContactTime} second` : ''}</p>
                            <p><strong>Left:</strong> {patientWorkoutDetail?.leftGroundContactTime != null ? `${patientWorkoutDetail.leftGroundContactTime} second` : ''}</p>
                        </PanelBarItem>
                        <PanelBarItem title="Stance Phase Mean">
                            <p><strong>Right:</strong> {patientWorkoutDetail?.stancePhaseRightMean != null ? `${patientWorkoutDetail.stancePhaseRightMean}%` : ''}</p>
                            <p><strong>Left:</strong> {patientWorkoutDetail?.stancePhaseLeftMean != null ? `${patientWorkoutDetail.stancePhaseLeftMean}%` : ''}</p>
                        </PanelBarItem>

                        <PanelBarItem title="Swing Phase Mean">
                            <p><strong>Right:</strong> {patientWorkoutDetail?.swingPhaseRightMean != null ? `${patientWorkoutDetail.swingPhaseRightMean}%` : ''}</p>
                            <p><strong>Left:</strong> {patientWorkoutDetail?.swingPhaseLeftMean != null ? `${patientWorkoutDetail.swingPhaseLeftMean}%` : ''}</p>
                        </PanelBarItem>

                        <PanelBarItem title="Single Support Mean">
                            <p><strong>Right:</strong> {patientWorkoutDetail?.singleSupportRightMean != null ? `${patientWorkoutDetail.singleSupportRightMean}%` : ''}</p>
                            <p><strong>Left:</strong> {patientWorkoutDetail?.singleSupportLeftMean != null ? `${patientWorkoutDetail.singleSupportLeftMean}%` : ''}</p>
                        </PanelBarItem>

                        <PanelBarItem title="Double Support Mean">
                            <p><strong>Right:</strong> {patientWorkoutDetail?.doubleSupportRightMean != null ? `${patientWorkoutDetail.doubleSupportRightMean}%` : ''}</p>
                            <p><strong>Left:</strong> {patientWorkoutDetail?.doubleSupportLeftMean != null ? `${patientWorkoutDetail.doubleSupportLeftMean}%` : ''}</p>
                        </PanelBarItem>
                    </PanelBar>

                    <p><strong>Progress Percentage:</strong> {patientWorkoutDetail?.progressPercentage}%</p>
                </CardBody>
            </Card>
        </div>
    );
};

export default PatientWorkoutDetailComponent;
