import api from "../../utils/api";
import { setHospitals } from "../reducers/hospital";
import { Dispatch } from 'redux';


export const getHospitals = () => async (dispatch: Dispatch) => {
    return await api.getHospitalsAsync().then((res) => {
        dispatch(setHospitals(res));
    }).catch(err => {
        throw err;
    });
};