import * as React from 'react';
import { SchedulerEditItem, SchedulerEditItemProps } from '@progress/kendo-react-scheduler';
import Patient from '../../store/types/Patient';
import Staff from '../../store/types/Staff';
import { Department } from '../../store/types';

export const EditItemWithDynamicTitle = (props: SchedulerEditItemProps) => {

    return (<SchedulerEditItem {...props}
        className={generateClass(props.dataItem)} // 임시적으로 한글 클래스명 사용
        title={generateTitle(props.dataItem)}
    />);
};

const generateTitle = (dataItem: { patient: Patient, staff: Staff, department: Department; calssName: 'Department;' }) => {
    const patient = dataItem.patient ? dataItem.patient.name : "김환자";
    const staff = dataItem.staff ? dataItem.staff.name : "김의사";
    const department = dataItem.department ? dataItem.department.name : "내과";
    return `${staff}(${department}) - ${patient}(환자)`;
};


const generateClass = (dataItem: { department: Department }) => {
    const className = dataItem.department ? dataItem.department.name : ''

    return `departmentTag ${className}`
}
